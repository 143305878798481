<template>
  <div class="ele-body ele-body-card">
    <!-- 顶部卡片 -->
    <el-card shadow="never" body-style="padding: 20px;">
      <div class="ele-cell workplace-user-card">
        <div class="ele-cell-content ele-cell">
          <el-avatar
            v-if="loginUser.user && loginUser.user.avatar"
            :size="68"
            :src="$http.defaults.baseIMGURL + loginUser.user.avatar"
          />
          <div class="ele-cell-content">
            <h4 class="ele-elip">
              早安，{{ loginUser.nickname }}，开始您一天的工作吧！
            </h4>
            <div
              class="ele-text-secondary ele-elip"
              style="margin-top: 8px; white-space: inherit"
            >
              <!-- <i class="el-icon-heavy-rain"></i> -->
              <div v-if="weather.forecast">
                今日 {{ weather.forecast[0].type }}，{{
                  weather.forecast[0].low
                }}
                - {{ weather.forecast[0].high }}，{{ weather.ganmao }}
              </div>
              <!-- <span><s /><s />今日 {{weather.forecast[0].type}}，{{weather.forecast[0].low}} - {{weather.forecast[0].high}}，{{weather.ganmao}}</span> -->
            </div>
          </div>
        </div>
        <div class="workplace-count-group">
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <el-tag size="small" class="ele-tag-round">
                <i class="el-icon-menu"></i>
              </el-tag>
              <span class="workplace-count-name">用户量</span>
            </div>
            <div class="workplace-count-num">{{ homeData.studentCount }}</div>
          </div>
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <el-tag type="warning" size="small" class="ele-tag-round">
                <i class="el-icon-finished"></i>
              </el-tag>
              <span class="workplace-count-name">日访问量</span>
            </div>
            <div class="workplace-count-num">{{ homeData.loginCount }}</div>
          </div>
          <div class="workplace-count-item">
            <div class="workplace-count-header">
              <el-tag type="success" size="small" class="ele-tag-round">
                <i class="el-icon-bell"></i>
              </el-tag>
              <span class="workplace-count-name">待处理</span>
            </div>
            <div class="workplace-count-num">{{ homeData.pendCount }}</div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 最新动态、我的任务、本月目标 -->
    <el-row :gutter="15">
      <el-col :sm="16">
        <el-card shadow="never" header="用户注册量" body-style="padding: 0;">
          <!-- <el-col :sm="10"> -->
          <div class="choice_date">
            <div
              :class="['date_item', currDateIndex == 0 ? 'curr_date_item' : '']"
              @click="changeDateIndex(0)"
            >
              近一周
            </div>
            <div
              :class="['date_item', currDateIndex == 1 ? 'curr_date_item' : '']"
              @click="changeDateIndex(1)"
            >
              近30天
            </div>
            <div
              :class="['date_item', currDateIndex == 2 ? 'curr_date_item' : '']"
              @click="changeDateIndex(2)"
            >
              近一年
            </div>
          </div>
          <!-- </el-col> -->
          <ele-chart :option="registrationChartOption" style="height: 300px" />
        </el-card>
      </el-col>
      <el-col :sm="8">
        <el-card shadow="never" header="学生等级">
          <ele-chart :option="studentGradeChartOption" style="height: 320px" />
        </el-card>
      </el-col>
      <el-col :sm="24">
        <el-card
          class="workplace-table-card"
          shadow="never"
          header="模块测试数据"
          body-style="padding: 12px;"
        >
          <el-card shadow="never" body-style="padding: 0;">
            <div class="ele-cell demo-monitor-tool">
              <el-col :sm="22">
                <div class="ele-inline-block hidden-xs-only">
                  <el-radio-group
                    v-model="saleSearch.dateType"
                    size="small"
                    style="margin-right: 10px"
                  >
                    <el-radio-button :label="1">本周</el-radio-button>
                    <el-radio-button :label="2">本月</el-radio-button>
                    <el-radio-button :label="3">本年</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="ele-inline-block hidden-xs-only">
                  <el-date-picker
                    v-model="saleSearch.datetime"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd HH:mm"
                    size="small"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    unlink-panels
                  />
                </div>
              </el-col>

              <el-col :sm="2" class="examination">
                <span>总考试量</span>
                <span style="color: #1986ff">{{ moduleData.testAll }}</span>
              </el-col>
            </div>

            <!-- 表格展示区域 -->
            <div class="histogram_chart">
              <ele-chart :option="moduleChartOption" style="height: 320px" />
            </div>

            <el-divider />
          </el-card>
        </el-card>
      </el-col>
    </el-row>

    <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp"
    ></iframe>
  </div>
</template>
<script>
import axios from "axios";
import EleChart from "ele-admin/packages/ele-chart";
export default {
  name: "DashboardWorkplace",
  components: { EleChart },
  data() {
    return {
      saleSearch: {
        type: "saleroom",
        dateType: 1,
        datetime: "",
      },
      // 柱状图数据
      moduleData: [],
      // 注册量折线图数据
      registrationData: {},
      // 学生等级饼图
      studentGradeData: [],
      // 注册量 当前选中tips高亮
      currDateIndex: 0,
      // 首页数据
      homeData: {},
      //注册量时间 1 近一周、2近30天、3近一年
      user_time_type: 1,
      // 模块测试数据 1 本周 2 本月 3 本年 4 自定义
      test_time_type: 1,
      // 自定义时间
      created_at: [],
      // 天气数据
      weather: [],
      // 当前城市
      currCity: "济南市", // 默认
    };
  },
  mounted() {
    // 获取首页数据
    this.getHomeData();
    // 获取天气
    // this.getWeather();
    // 获取位置和天气信息
  },
  methods: {
    // 获取首页数据
    getHomeData() {
      this.$http
        .get("/Admin/index", {
          params: {
            user_time_type: this.user_time_type,
            test_time_type: this.test_time_type,
            created_at: this.created_at,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.homeData = data.data;
           // console.log(data.data);
            // 折线图
            this.registrationData = data.data.userData;
            // 饼图
            this.studentGradeData = data.data.levelData;
            // 柱状图
            this.moduleData = data.data.testDataBar;
            this.Tmap();
          }
        });
    },
    // 获取天气接口
    getWeather() {
      // let ctiyName = returnCitySN["cname"];
      // let arr;
      // if (ctiyName.search("省")) {
      //   arr = ctiyName.split("省");
      //   let province = arr[0];
      //   arr = arr[1].split("市");
      //   ctiyName = province;
      // } else {
      //   arr = ctiyName.split("市");
      //   ctiyName = arr[0];
      // }
      axios
        .get(
          "http://wthrcdn.etouch.cn/weather_mini?city=" + this.currCity ||
            "济南市"
        )
        .then(({ request }) => {
          this.weather = JSON.parse(request.responseText).data;
        //  console.log(this.weather);
        });
    },
    Tmap() {
      window.addEventListener(
        "message",
        function (event) {
          var loc = event.data;
          this.opp = loc.city;
          this.currCity = opp;
        },
        false
      );
      this.getWeather();
    },
    changeDateIndex(index) {
      console.log(index);
      this.currDateIndex = index;
      this.user_time_type = index + 1;
    },
  },
  computed: {
    // 当前登录用户信息
    loginUser() {
      //console.log(this.$store.state.user.user);
      return this.$store.state.user.user;
    },
    // 模块测试数据柱状图配置
    moduleChartOption() {
      if (!this.moduleData.bar) return;
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: this.moduleData.bar.map((d) => d.name),
          right: "0",
        },
        xAxis: [
          {
            type: "category",
            data: this.moduleData.xdata,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: this.moduleData.bar.map((val) => {
          return {
            name: val.name,
            type: "bar",
            data: val.count,
          };
        }),
      };
    },
    // 折线图数据
    registrationChartOption() {
      // if (!this.registrationData.length) return {};
      return {
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.registrationData.userDate,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            type: "line",
            areaStyle: {
              opacity: 0.5,
            },
            data: this.registrationData.userCount,
          },
        ],
      };
    },
    // 学生等级饼图
    studentGradeChartOption() {
      if (!this.studentGradeData.length) return;
      return {
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: this.studentGradeData.map((d) => d.name),
          bottom: 5,
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "70%"],
            center: ["50%", "43%"],
            label: {
              show: false,
            },
            data: this.studentGradeData,
          },
        ],
      };
    },
  },
  watch: {
    // 监听用户注册量时间改变
    user_time_type() {
      this.getHomeData();
    },
    // 点击模块测试数据 周 月 年
    "saleSearch.dateType": {
      handler(value) {
        this.test_time_type = value;
        // 重新获取数据
        this.getHomeData();
      },
    },
    // 选择自定义数据
    "saleSearch.datetime": {
      handler(value) {
        // 赋值
        if (Array.isArray(value)) {
          this.created_at = value;
         // console.log(this.created_at);
          // 时间类型改为4
          this.test_time_type = "4";
          // 取消高亮
          this.saleSearch.dateType = 4;
          // 重新获取数据
          this.getHomeData();
        }
      },
    },
    // saleSearch: {
    //   handler(time) {
    //     // 本周 本月 本年类型
    //     this.test_time_type = time.dateType;
    //     console.log(this.saleSearch);
    //     // 如果选择了自定义时间
    //     if (Array.isArray(time.datetime)) {
    //       this.created_at = time.datetime;
    //       this.test_time_type = 4;
    //       this.saleSearch.dateType = 4
    //     }
    //     // 重新获取数据
    //     this.getHomeData();
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped>
/* 顶部用户信息卡片 */
.workplace-user-card .ele-cell-content {
  overflow: hidden;
}

.workplace-count-group {
  white-space: nowrap;
}

.workplace-count-item {
  padding: 0 5px 0 25px;
  box-sizing: border-box;
  display: inline-block;
  text-align: right;
}

.workplace-count-name {
  padding-left: 8px;
}

.workplace-count-num {
  font-size: 24px;
  margin-top: 6px;
}

@media screen and (max-width: 992px) {
  .workplace-count-item {
    padding: 0 5px 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .workplace-user-card,
  .workplace-count-group {
    display: block;
  }

  .workplace-count-group {
    margin-top: 15px;
    text-align: right;
  }
}

/* 快捷方式 */
.app-link-block {
  display: block;
  color: inherit;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.app-link-block .app-link-icon {
  color: #69c0ff;
  font-size: 30px;
  margin-top: 5px;
}

.app-link-block .app-link-title {
  margin-top: 8px;
}

/* 最新动态时间轴 */
.ele-timeline-act {
  padding-left: 50px;
}

.ele-timeline-act ::v-deep .el-timeline-item__timestamp {
  margin: 0;
  position: absolute;
  top: 3px;
  left: -45px;
}

.ele-timeline-act ::v-deep .el-timeline-item {
  padding-bottom: 19px;
}

.ele-timeline-act ::v-deep .el-timeline-item:last-child {
  padding-bottom: 0;
}

/* 表格 */
.workplace-table-card ::v-deep .el-table tbody > .el-table__row:last-child td {
  border-bottom: none;
}

.workplace-table-card ::v-deep .el-table:before {
  display: none;
}

.workplace-table-card .sort-handle {
  cursor: move;
  font-size: 18px;
  vertical-align: middle;
}

.workplace-table-card ::v-deep .el-table__row.sortable-chosen {
  background-color: hsla(0, 0%, 60%, 0.1);
}

.workplace-table-card ::v-deep .el-table__row.sortable-chosen td {
  background-color: transparent;
}

/* 本月目标 */
.workplace-goal-group {
  text-align: center;
  position: relative;
  padding: 48px 0;
}

.workplace-goal-group .workplace-goal-content {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
}

.workplace-goal-group .workplace-goal-num {
  font-size: 40px;
  margin-top: 15px;
}

/* 小组成员 */
.user-list-item {
  padding: 13px 18px;
}

.user-list-item + .user-list-item {
  border-top: 1px solid hsla(0, 0%, 60%, 0.15);
}

.user-list-item .ele-cell-desc {
  margin-top: 5px;
}

/* 以下为新增样式 */

.examination {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
}
.examination span:nth-child(2) {
  display: block;
  font-weight: 700;
  font-size: 20px;
}

.histogram_chart {
  margin-top: 20px;
  height: 400px;
}

.choice_date {
  display: flex;
  margin-left: 50px;
  width: 200px;
  height: 50px;
  line-height: 50px;
}
.choice_date .date_item {
  flex: 1;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.curr_date_item {
  color: #1c82ea;
}
</style>
